* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f7f7f7;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #9e9ff3, #b3b3ff);
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.shop-name {
  font-size: 2em;
  color: #fff;
  font-weight: 700;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.nav {
  display: flex;
  gap: 15px;
}

.nav a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}

.nav a:hover {
  background-color: #ffcc00;
  color: #333;
}

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 100px;
  height: 100px;
  background-image: url('https://www.thpromotion.com/wp-content/uploads/2023/10/eveandboy-logo-400x400-1.webp');
  background-size: cover; 
  background-position: center; 
  border-radius: 50%; 
  margin-right: 20px;
}

.profile-info h2 {
  font-size: 22px;
  color: #333;
}

.profile-info a {
  color: #f76b1c;
  text-decoration: none;
}

.profile-info a:hover {
  text-decoration: underline;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-card {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease, scale 0.3s ease;
  width: 220px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.product-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 3px solid #f0f0f0;
}

.product-title {
  font-size: 1.1em;
  color: #333;
  padding: 15px;
  text-align: center;
  line-height: 1.6;
  font-weight: bold;
  background-color: #f9f9f9;
}

.product-card .badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(to right, #ff6347, #ff8566);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }

  .header {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .shop-name {
    font-size: 1.8em;
  }

  .nav {
    margin-top: 15px;
    text-align: center;
    gap: 10px;
  }

  .nav a {
    font-size: 14px;
    padding: 5px;
  }

.product-detail {
  margin: 20px auto;
  max-width: 1000px;
  font-family: Arial, sans-serif;
}

.header-bar {
  text-align: center;
  margin-bottom: 30px;
}

.header-bar h3 {
  font-size: 24px;
  color: #333;
}

.product-info {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.product-image {
  flex: 1;
}

.product-img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
}

.product-details {
  flex: 2;
}

.product-name {
  font-size: 26px;
  color: #333;
  margin-bottom: 10px;
}

.price-container {
  margin-bottom: 15px;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
}

.discounted-price {
  color: #e74c3c;
  font-size: 22px;
  font-weight: bold;
}

.quantity-controls {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.quantity-controls .btn {
  padding: 5px 10px;
}

.action-buttons button {
  margin-top: 15px;
  width: 100%;
  padding: 10px;
}

.action-buttons .btn {
  margin-bottom: 10px;
}

.recommended-store {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.store-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.store-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.store-info {
  flex-grow: 1;
}

.store-info h5 {
  font-size: 20px;
  color: #333;
  margin-bottom: 5px;
}

.store-info p {
  font-size: 14px;
  color: #777;
}

.recommended-store button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-description {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 8px;
}

.product-description h4 {
  font-size: 22px;
  margin-bottom: 15px;
}

.product-description p {
  font-size: 16px;
  color: #333;
}

}
