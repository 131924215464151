.product-details {
    padding: 20px;
}

.product-main {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #aeaeae; 
    padding-bottom: 20px; 
}

.product-image img {
    max-width: 300px; 
    height: 300px; 
    object-fit: contain; 
    background-color: #bcbcbc;
}


.product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-info h2 {
    font-size: 1.5rem;
}

.product-info p {
    margin: 10px 0;
   
}

.product-buttons button {
    margin-right: 10px;
    
}

.product-description {
    margin-top: 20px;
     
}

.product-description h3 {
    margin-bottom: 10px;
    border-top: 1px solid #aeaeae; 
    padding-top: 5px;
}

.product-store-profile {
    display: flex;
    align-items:  center;
    gap: 20px;
}

.product-store-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #d3d3d3;
}

.product-store-details {
    display: flex;
    align-items: center;
    gap: 50px;
}

.product-store-details h3 {
    margin-right:100px;
}

.product-store-details button {
    width: fit-content;
    padding: 5px 10px;
    font-size: 0.9rem;
    cursor: pointer;
}