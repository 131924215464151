.navbar-custom {
    background-color: #000524; /* สีชมพูพาสเทล */
}

.store-logo {
    width: 70px;
    height: auto;
    border-radius: 50%; /* ปรับให้โลโก้เป็นวงกลม */
}

.store-name {
    font-size: 1.5rem;
    font-weight: bold;
    
}

.shadow-btn {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
