.product-card {
    border: 1px solid #ddd; /* กรอบบางๆ */
    border-radius: 8px; /* มุมโค้ง */
    overflow: hidden; /* ให้รูปภาพถูกครอบในกรอบ */
    margin: 10px; /* ระยะห่างระหว่างการ์ด */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* เพิ่มการเปลี่ยนแปลง */
}

.product-card:hover {
    transform: scale(1.05); /* ขยายขนาดเมื่อ hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* เพิ่มเงา */
}

.product-card img {
    width: 100%; /* ให้รูปภาพกว้างเท่ากับการ์ด */
    height: 200px; /* กำหนดความสูงของภาพ */
    object-fit: cover; /* ให้ภาพไม่ยืด */
}

.product-card .card-body {
    padding: 15px; /* ระยะห่างของเนื้อหาในการ์ด */
    background-color: #f9f9f9; /* สีพื้นหลัง */
}

.product-card .card-title {
    font-size: 1.2rem; /* ขนาดตัวอักษรชื่อสินค้า */
    font-weight: bold; /* ทำให้ตัวอักษรหนา */
    margin-bottom: 10px; /* ระยะห่างด้านล่าง */
}

.product-card .card-text {
    color: #555; /* เปลี่ยนสีข้อความ */
    font-size: 1rem;
    margin-top: 5px; /* ระยะห่างระหว่างชื่อสินค้าและราคา */
}

/*//////////////////////////////////////////*/












  
  /*ตกแต่งหน้ารายละเอียดสินค้าหน้าสินค้าข้างใน*/
  .product-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-info {
    display: flex;
    gap: 20px;
  }
  
  .product-image {
    flex: 1;
  }
  
  .product-details {
    flex: 2;
  }
  
  .product-price {
    margin-top: 10px;
  }
  
  .original-price {
    text-decoration: line-through;
    margin-right: 10px;
    color: #888;
  }
  
  .discounted-price {
    font-weight: bold;
    color: #f00;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .recommended-store {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
  }
  
  .store-profile {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .product-description {
    margin-top: 20px;
  }
  
 /*ตกแต่งหน้าapp*/
/* src/App.css */
.store-header {
    background-color: #80a3e8;
    color: white;
    padding: 30px; /* ลด padding เพื่อให้ชื่อร้านอยู่ใกล้กับรูปโปรไฟล์มากขึ้น */
    border-radius: 15px 15px 0 0; /* เพิ่มความโค้งมน */
    text-align: center; /* จัดข้อความให้อยู่กลาง */
}

.store-logo {
    width: 150px; /* ปรับขนาดรูปโปรไฟล์ให้ใหญ่ขึ้น */
    height: 150px; /* ปรับขนาดรูปโปรไฟล์ให้ใหญ่ขึ้น */
    border-radius: 50%;
    border: 5px solid white; /* เพิ่มขอบรูปให้หนาขึ้น */
    margin-bottom: 5px; /* ลดระยะห่างจากชื่อร้าน */
}

.store-name {
    font-size: 28px; /* ขนาดตัวอักษรใหญ่ขึ้น */
    font-weight: bold;
    margin-top: 0px; /* ลดระยะห่างจากรูปโปรไฟล์ให้ใกล้ขึ้น */
}

.store-description {
    font-size: 18px; /* ขนาดตัวอักษรใหญ่ขึ้น */
    color: #666;
    margin-top: 10px; /* เพิ่มระยะห่างจากชื่อร้าน */
}

  
  /* ให้รูปโปรไฟล์เป็นวงกลม */
.store-logo {
    border-radius: 50%;
    width: 120px; /* ขนาดรูปโปรไฟล์เล็กลง */
    height: 120px; /* ขนาดรูปโปรไฟล์เล็กลง */
    object-fit: cover; /* ให้รูปภาพเต็มพื้นที่โดยไม่เสียสัดส่วน */
    border: 5px solid white; /* เพิ่มขอบรูปโปรไฟล์ให้หนาขึ้น */
    margin-bottom: 5px; /* ลดระยะห่างจากชื่อร้าน */
}

.store-header {
    background-color: #f3f4f6; /* สีพื้นหลังที่สบายตา */
    color: #333; /* สีข้อความเข้มเพื่อให้ดูชัดเจน */
    padding: 20px;
    border-radius: 15px 15px 0 0; /* เพิ่มความโค้งมน */
    text-align: center;
}

.store-name {
    font-size: 28px;
    font-weight: bold;
    margin-top: 2px; /* ลดระยะห่างจากรูปโปรไฟล์ */
    margin-bottom: 2px; /* ลดระยะห่างระหว่างชื่อร้านกับคำอธิบาย */
}

.store-description {
    font-size: 16px; /* ขนาดตัวอักษรเล็กลง */
    color: #666;
    margin-top: 0; /* ลดระยะห่างจากข้อความก่อนหน้า */
    margin-bottom: 5px; /* เพิ่มระยะห่างน้อย ๆ กับข้อความถัดไป */
}




.store-profile-card {
  border: none;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.store-header {
  padding: 20px;
}

/* สไตล์สำหรับโลโก้ร้าน */
.store-logo {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* สไตล์สำหรับชื่อร้าน */
.store-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

/* สไตล์สำหรับข้อความสถานะ */
.store-status {
  font-size: 0.9rem;
  color: gray;
  margin-bottom: 10px;
}

/* สไตล์สำหรับปุ่ม "ติดตาม" และ "พูดคุย" */
.store-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.store-buttons button {
  padding: 5px 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.store-buttons button:hover {
  border-color: #999;
}

/* สไตล์สำหรับรายละเอียดร้านค้า */
.store-description {
  color: #FF6347;
  cursor: pointer;
  font-size: 0.9rem;
}

.store-description:hover {
  color: #ff4500; /* เปลี่ยนสีเมื่อวางเมาส์ */
}

/* สไตล์สำหรับข้อมูลติดต่อ */
.contact-info h5 {
  font-weight: bold;
  color: #333;
}

.contact-info p {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}

/* กำหนดขนาดของ Card และการจัดเรียงในส่วนของ Product */
.product-card {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

/*กรอบสินค้า*/
/* กำหนดขนาดคงที่ของ Card เพื่อให้ขนาดเท่ากันทุกอัน */
.product-card {
  width: 180px; /* กำหนดความกว้างของ Card */
  height: 320px; /* กำหนดความสูงของ Card */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

/* กำหนดขนาดของรูปภาพให้มีขนาดเท่ากันทุก Card */
.product-card img {
  width: 100%;
  height: 180px; /* ปรับขนาดรูปภาพให้เท่ากัน */
  object-fit: cover; /* ทำให้รูปภาพเต็มขนาดที่กำหนดโดยไม่บิดเบี้ยว */
  border-bottom: 1px solid #e0e0e0;
}

/* การจัดเรียงชื่อสินค้า */
.product-card .card-text {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2em;
  color: #333;
  margin: 10px;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px; /* กำหนดความสูงให้ส่วนชื่อสินค้า เพื่อให้สม่ำเสมอ */
}

/* การจัดเรียงราคา */
.product-card .price {
  font-size: 1.1rem;
  color: #d9534f;
  font-weight: bold;
  margin: 0 10px 10px;
  text-align: left;
}

/* จัดระยะห่างของแต่ละ Card */
.product-card-container {
  margin-bottom: 20px;
}













.recommended-products-section {
  margin-top: 20px;
}

.recommended-products-section h3 {
  font-size: 1.5rem;
  color: #ff6347;
  margin-bottom: 15px;
  text-align: center;
}

.recommended-product-card {
  width: 180px;
  height: 320px;
  border: 2px solid #ff6347;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.recommended-product-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
}

.recommendation-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff6347;
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 3px;
}

.recommended-product-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.recommended-product-card .product-info {
  padding: 10px;
  text-align: left;
}

.recommended-product-card .product-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recommended-product-card .price {
  font-size: 1.1rem;
  color: #d9534f;
  font-weight: bold;
  margin-top: 5px;
}
