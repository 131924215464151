.footer {
    background-color: #000524;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .footer-section {
    flex: 1;
    margin: 10px;
  }
  
  .footer-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin: 5px 0;
  }
  
  .footer-section ul li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-bottom {
  
    padding: 10px;
    color: #bbb;
  }
  