.store-profile {
    padding: 10px 0; 
    gap: 20px;
}

.store-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.store-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #f8d7da;
    text-align: center;
    line-height: 100px;
    flex-shrink: 0;
}

.store-info {
    margin-left: 20px;
    flex-grow: 1;
}

.store-info h2 {
    font-size: 1.8rem;
}

.store-content {
    display: flex;
    flex-direction: column;
}

.product-header {
    font-weight: bold;
    margin-top: 20px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
    padding: 0 20px;
}

.product-item {
    background-color: #ffffff;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;
}

.product-item:hover {
    transform: translateY(-5px);
}

.store-image img {
    max-width: 100%;  
    max-height: 100%;  
    object-fit: cover;
    border-radius: 50%; 
    border-radius: 5px;    
}

.products-image img {
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: cover; 
    border-radius: 5px;
}
.no-image-placeholder {
    width: 100%;
    height: 150px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
}